import React, {useState} from 'react'
// import navStyles from './mods/nav.module.css'
import apple from '../images/apple.png'
import spotify from '../images/spotify.png'
import google from '../images/google.png'
import pandora from '../images/pandora.jpeg'
import overcast from '../images/overcast.png'
import rss from '../images/rss.png'

export default function Podlinks() {
    
    const [formState, setFormState] = useState({
        email:""
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleChange = e => {
        setFormState({
           email: e.target.value
        })
    }
    
    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "signup", ...formState })
          })
            .then(() => alert("Success!"))
            .then(() => setFormState({email: ""}))
            .catch(error => alert(error));

        e.preventDefault()
    }

    return (
      <React.Fragment>
            <form 
                className="flex font-display flex-col lg:flex-row justify-center py-1 mt-3 mb-2 mx-auto w-11/12 sm:w-8/12 md:w-full" 
                name="signup" 
                method="POST" 
                data-netlify="true" 
                netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >

            <div className="flex relative">
                <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm focus-within:text-gray-600">
                    <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                        </path>
                    </svg>
                </span>
                <input 
                    type="email" 
                    className="flex-1 appearance-none text-sm md:text-xs border border-gray-300 w-full py-1 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400 active:flex-grow focus:border-transparent rounded-r-md lg:rounded-r-none" 
                    name="email" 
                    placeholder="Email"
                    onChange={handleChange}
                    value={formState.email}
                />
                </div>

                {/* <input  className="w-9/12 flex-shrink border border-green-300 rounded focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent shadow-md" placeholder="email"/> */}
                <button className="flex-shrink font-semibold w-2/11 rounded-r-md rounded-l-md lg:rounded-l-none text-center text-sm text-white text-display bg-red-600 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 py-2 md:py-1 px-1 my-1 lg:my-0 shadow-sm" type="submit">Submit</button>
            </form>
        <div className={null}>
            <h4 className="pod-title text-xl font-mon font-semibold text-center text-red-700 mt-4 mb-4">Podcast Players</h4>
            <ul className="flex flex-row justify-center mx-0 mt-2 md:hidden">
                <a href="https://podcasts.apple.com/us/podcast/the-narrative-monopoly/id1550524077"><li><img src={apple} alt="Listen on Apple Podcasts" className="w-14 h-14 rounded-xl"/></li></a>
                <a href="https://open.spotify.com/show/2VJXcgRBLEkXuJmWBFyn6p"><li><img src={spotify} alt="Listen on Spotify" className="w-14 h-14 rounded-xl mx-8"/></li></a>
                <a href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9mZWVkcy5zaW1wbGVjYXN0LmNvbS8xX0U1UEVqcw%3D%3D"><li className="border border-black border-opacity-40 rounded-xl"><img src={google} alt="Listen on Google Podcasts" className="w-14 h-14 rounded-2xl p-0.5"/></li></a>
            </ul>
            <ul className="hidden 2xl:mx-12 md:flex flex-col justify-center ml-5 mr-4 mt-3">
                <a href="https://podcasts.apple.com/us/podcast/the-narrative-monopoly/id1550524077"><li className="flex items-center font-mon py-1.5 pl-1.5 text-sm bg-gradient-to-r hover:from-red-400 hover:to-purple-500  hover:shadow-2xl rounded-2xl"><img src={apple} alt="Listen on Apple Podcasts" className="w-10 h-10 rounded-xl mr-3"/>Apple Podcasts</li></a>
                <a href="https://open.spotify.com/show/2VJXcgRBLEkXuJmWBFyn6p"><li className="flex items-center font-mon py-1.5 pl-1.5 text-sm  bg-gradient-to-r hover:from-white hover:to-green-500  hover:shadow-2xl rounded-2xl"><img src={spotify} alt="Listen on Spotfiy Podcasts" className="w-10 h-10 rounded-xl mr-3"/>Spotify</li></a>
                <a href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9mZWVkcy5zaW1wbGVjYXN0LmNvbS8xX0U1UEVqcw%3D%3D"><li className="flex items-center font-mon py-1.5 pl-1.5 text-sm rounded-2xl bg-gradient-to-r hover:from-white hover:via-yellow-200 hover:to-red-500  hover:shadow-2xl"><img src={google} alt="Listen on Google Podcasts" className="border border-solid border-black border-opacity-40 w-10 h-10 rounded-xl p-0.5 mr-3"/>Google Podcasts</li></a>
                <a href="https://www.pandora.com/podcast/the-narrative-monopoly/PC:58653?part=PC:58653&corr=podcast_organic_external_site&TID=Brand:POC:PC58653:podcast_organic_external_site"><li className="flex items-center font-mon py-1.5 pl-1.5 text-sm  bg-gradient-to-r hover:from-white hover:to-blue-500  hover:shadow-2xl rounded-2xl"><img src={pandora} alt="Listen on Spotfiy Podcasts" className="w-10 h-10 rounded-xl mr-3"/>Pandora</li></a>
                <a href="https://overcast.fm/itunes1550524077"><li className="flex items-center font-mon py-1.5 pl-1.5 text-sm  bg-gradient-to-r hover:from-white hover:to-yellow-600  hover:shadow-2xl rounded-2xl"><img src={overcast} alt="Listen on Overcast" className="w-10 h-10 rounded-xl mr-3"/>Overcast</li></a>
                <a href="https://feeds.simplecast.com/1_E5PEjs"><li className="flex items-center font-mon py-1.5 pl-1.5 text-sm  bg-gradient-to-r hover:from-white hover:to-yellow-500  hover:shadow-2xl rounded-2xl"><img src={rss} alt="Listen with the RSS Feed on your fav player" className="w-10 h-10 rounded-xl mr-3"/>RSS Feed</li></a>
            </ul>
        </div>
    </React.Fragment>      
    )
}

