import React from 'react'
import PropTypes from 'prop-types'
import Posts from './posts'
import Feed from './feed'


//https://www.gatsbyjs.com/plugins/gatsby-source-simplecast/?=simplecast

export default function Content ({ navSelect }) { 
      if (navSelect === "Writing") {
          return (
              <Posts />
          )
      } else if (navSelect === "Episodes") {
          return (
              <Feed />
        )
      } else {
            return (
            <React.Fragment>
              <div className="font-display text-lg leading-snug bg-white bg-opacity-10 w-11/12 mx-auto">
                <p>The Narrative Monopoly Podcast explores monopolized narratives and their consequences. Narratives are *the* driving force in society, making their exploration essential.</p>
                <p>Just as importantly, the podcast itself isn't meant to singularly focus on <i>narrative this, narrative that</i> (though this will happen at times). It's meant to serve as a forum for discussions outside monopolized narratives. So many of today's conversations are forced into neatly organized narratives, but the world is messy and complex -- not neat and orderly.</p>
                <p>The Narrative Monopoly Podcast seeks nuance and context above all else.</p>
                <p><b>Subscribe via your favorite podcast player and sign up for email updates (sent infrequently).</b></p>
            </div>
            </React.Fragment> 
        )}
   } 
