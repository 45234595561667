import React from 'react'
import PropTypes from 'prop-types'
import Title from './title'

// add ep pages here
// if no state, turn this into a function 

export default class Feed extends React.Component { 
    constructor(props) { 
        super(props)
 
        this.state = {
         //null
        }
      //bind here
    }

      //Lifecycle methods & other methods here
    render() {  
      return (
      <React.Fragment> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/38-erik-torenberg-narratives/id1550524077?i=1000565621483">#38 - Erik Torenberg, Narratives</a></h3>
          <iframe  className="mb-0 h-auto w-full border-black border border-solid rounded-md"  width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/b7b5ffe8-4bf3-4e87-9f14-ed809398820d?dark=false&color=D2F7D7"></iframe>
      </div>    
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/37-eugene-volokh-free-speech/id1550524077?i=1000559686174">#37 - Eugene Volokh, Free Speech</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/164d9d50-2772-4d9c-b57e-54a7e1d6b0be?dark=false&color=D2F7D7"></iframe>
      </div> 
       <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/36-michael-s-malone-the-big-score/id1550524077?i=1000559082771">#36 - Michael S. Malone, The Big Score</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/3feb024b-4757-463c-9afd-0812fd078900?dark=false&color=D2F7D7"></iframe>
      </div> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/35-michael-fritzell-asian-century-stocks/id1550524077?i=1000545152223">#35 - Michael Fritzell, Asian Century Stocks</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/48a7cd2d-f322-4260-8a93-a7457c147351?dark=false&color=D2F7D7"></iframe>
      </div> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/34-john-cochrane-the-return/id1550524077?i=1000542811877">#34 - John Cochrane, The Return</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/81524f0b-5fd1-4b8f-928c-7e0e913f1982?dark=false&color=D2F7D7"></iframe>
      </div> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/33-james-poulos-human-forever/id1550524077?i=1000542319452">#33 - James Poulos, Human Forever</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/73b924ed-8567-4026-94ff-a20aa2d3d16d?dark=false&color=D2F7D7"></iframe>
      </div>   
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/32-david-sacks-callin/id1550524077?i=1000539865447">#32 - David Sacks, Callin</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/101fa114-a7a3-4a03-8161-f538740171b2?dark=false&color=D2F7D7"></iframe>
      </div> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/31-jamie-catherwood-investor-amnesia/id1550524077?i=1000539264702">#31 - Jamie Catherwood, Investor Amnesia</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/24572b24-07ee-48be-851d-5e24da0129e4?dark=false&color=D2F7D7"></iframe>
      </div> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/30-antonio-garc%C3%ADa-mart%C3%ADnez-the-pull-request/id1550524077?i=1000538556553">#30 - Antonio García Martínez, The Pull Request</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/d5715590-1cd6-4069-a7ad-3f3122fe6a5a?dark=false&color=D2F7D7"></iframe>
      </div> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/29-lanhee-chen-california-health-care/id1550524077?i=1000537709874">#29 - Lanhee Chen, California & Health Care</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/16ee95f4-8cb0-45ec-bb65-111d715a66d8?dark=false&color=D2F7D7"></iframe>
      </div> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/28-mark-nelson-nuclear-energy/id1550524077?i=1000536369736">#28 - Mark Nelson, Nuclear Energy</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/345bcc27-fb7c-462e-bb20-b328dec5d708?dark=false&color=D2F7D7"></iframe>
      </div> 
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/27-joel-kotkin-neo-feudalism/id1550524077?i=1000536119888">#27 - Joel Kotkin, Neo Feudalism</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/d5a9bd51-91f3-4e30-9f8e-894f286c09c9?dark=false&color=D2F7D7"></iframe>
      </div>             
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/26-anthony-pompliano-bitcoin-media/id1550524077?i=1000535153797">#26 - Anthony Pompliano, Bitcoin & Media</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/cbd7ea31-05df-4c99-8d41-10875f0643de?dark=false&color=D2F7D7"></iframe>
      </div>      
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/25-martin-gurri-callin-live-q-a/id1550524077?i=1000532292633">#25 - Martin Gurri, Callin live Q & A</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/e2887669-15b1-4c77-ba9e-ff6a8dc6e80c?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/24-john-cochrane-the-grumpy-economist/id1550524077?i=1000530384658">#24 - John Cochrane, The Grumpy Economist</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/384f4195-4193-4060-8608-b481649d47b8?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/23-max-towey-roca-news/id1550524077?i=1000528960880">#23 - Max Towey, Roca News</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/62df6c61-c0fc-4cd7-8849-f5c6d4a64c95?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/22-brian-riedl-budget-whisperer-debt-crusader/id1550524077?i=1000528218750">#22 - Brian Riedl, Budget Whisperer & Debt Crusader</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/75703c80-00ac-42da-bce7-ceaa21c872de?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/21-tom-joscelyn-al-qaeda-jihad/id1550524077?i=1000526718669">#21 - Tom Joscelyn, al-Qaeda & Jihad</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/beb664ce-2675-4dc0-a315-2b5f69856114?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/20-kat-cole-brand-distribution-leadership/id1550524077?i=1000525854588">#20 - Kat Cole, Brand, Distribution, & Leadership</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/25bdccea-ef8b-40ed-9563-1843dc6f233c?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/19-mike-maples-jr-relentless-optimist/id1550524077?i=1000524935985">#19 - Mike Maples, Jr., Relentless Optimist</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/e40849bc-590d-4ae2-b2ce-6fad6c14adaf?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/18-marty-bent-bitcoin-energy/id1550524077?i=1000524068104">#18 - Marty Bent, Bitcoin & Energy</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/6f3835ab-c1f1-4ca5-9c16-48592b1fdfd7?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/17-yuval-levin-institutions/id1550524077?i=1000523269783">#17 - Yuval Levin, Institutions</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/af552657-ab71-45d4-b544-4e918341ba06?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/16-geoff-lewis-vibe-capitalist/id1550524077?i=1000522495690">#16 - Geoff Lewis, Vibe Capitalist</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/d6c5d439-bb3a-4f5c-a0d5-1a2d97b023a4?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/15-mark-galeotti-russia/id1550524077?i=1000521574520">#15 - Mark Galeotti, Russia</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/5b9dc673-ccf2-4f91-bb8c-b6985e75fbe3?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/14-drew-holden-red-thread-redemption/id1550524077?i=1000520430188">#14 - Drew Holden, Red Thread Redemption</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/645b1034-49ea-49eb-b581-b81afaf7ef52?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/13-eliana-johnson-editor-in-chief-washington-free-beacon/id1550524077?i=1000519222306">#13 - Eliana Johnson, Editor-in-Chief of the Washington Free Beacon</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/c6d04c13-39e1-4cc6-8838-f7309a220042?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/12-robert-shiller-narrative-economics/id1550524077?i=1000518925443">#12 - Robert Shiller, Narrative Economics</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/c717d665-8ea7-43a4-b3e9-31bb22412616?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/11-philip-klein-editor-of-national-review-online/id1550524077?i=1000517361340">#11 - Philip Klein, Editor of National Review Online</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/5257c3ab-0029-4e67-967c-e24fbc6fbd00?dark=false&color=D2F7D7"></iframe>
      </div>
      <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/deconstructing-narrative-context-famous-vietnam-photo/id1550524077?i=1000516270118">[Deconstructing the Narrative] - Context of Famous Vietnam Phot‪o‬</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/5a4a4329-ac30-4a73-b009-1e0570025825?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/10-mike-solana-pirate-wires/id1550524077?i=1000515393004">#10 - Mike Solana, Pirate Wires</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/79fee729-16a3-4578-b95e-6f0318f4a25f?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/9-glenn-loury-race-economics/id1550524077?i=1000514349050">#9 - Glenn Loury, Race & Economics</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/5148bc39-fc42-4dab-b7a4-de59be290e66?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/8-adam-white-law-the-judiciary/id1550524077?i=1000513547581">#8 - Adam White, Law & the Judiciary</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/909e7fe7-40fd-4eab-937a-a2e02f827ae5?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/7-josh-holmes-future-of-the-gop/id1550524077?i=1000512390578">#7 - Josh Holmes, Future of the GOP</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/4726e6e0-410e-41e8-a1ff-48602408e8f7?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/6-bubba-atkinson-axios-ijr/id1550524077?i=1000511567934">#6 - Bubba Atkinson, Axios & IJR</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/64320434-c9aa-4488-81e7-88fe1f63244a?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/5-doug-ellin-entourage/id1550524077?i=1000510758774">#5 - Doug Ellin, Entourage</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/ff04210d-786e-44d7-a053-e052dd85c433?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/4-gerard-baker-wsj-editor-at-large/id1550524077?i=1000509665976">#4 - Gerard Baker, WSJ Editor at Large</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/c6003955-ccd8-4360-876f-d076ca20d2d3?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/3-ben-hunt-epsilon-theory/id1550524077?i=1000508757783">#3 - Ben Hunt, Epsilon Theory</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/88b390d2-2b4f-4dce-8a5a-c375adccaca0?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/2-jason-crawford-the-roots-of-progress/id1550524077?i=1000507890678">#2 - Jason Crawford, The Roots of Progress</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameBorder="no" scrolling="no" seamless src="https://player.simplecast.com/8286e287-5cb9-46cd-9693-075792d7d189?dark=false&color=D2F7D7"></iframe>
        </div>
        <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display"><a href="https://podcasts.apple.com/us/podcast/1-martin-gurri-revolt-of-the-public/id1550524077?i=1000507038679">#1 - Martin Gurri, Revolt of the Public</a></h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameBorder="no" scrolling="no" seamless src="https://player.simplecast.com/22e5408e-2cb1-4597-85ee-f62ebab15182?dark=false&color=D2F7D7"></iframe>
        </div>
        {/* <div className=" border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
          <h3 className="mb-3 font-display">Episode 1 - Martin Gurri, Revolt of the Public</h3>
          <iframe className="mb-0 h-auto w-full border-black border border-solid rounded-md" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/50e96654-21f5-4b76-b0cd-8dde594c12da?dark=false&color=D2F7D7"></iframe>
        </div> */}
      </React.Fragment> 
    ) 
   } 
 }
