import React from 'react'
import {Link, graphql, useStaticQuery } from 'gatsby'
import Layout from "./layout"
import SEO from "./seo"
import writingStyles from './mods/writing.module.css'
import Title from './title'
// import { RiExternalLinkLine } from 'react-icons/ri'
// import { linkResolver } from 'gatsby-source-prismic'


export default function Posts() {
    const data = useStaticQuery(graphql`
    query {
        allPrismicPost (sort: {fields: data___date, order: DESC}){
            edges {
              node {
                data {
                  body {
                    html
                  }
                  title {
                    text
                  }
                  subtitle {
                    text
                  }
                  date
                  tag
                }
                uid
              }
            }
          }
        }
    `)

    const posts = data.allPrismicPost.edges;
    // console.log(posts)
    return (
       <React.Fragment>
        <SEO title="Posts" />
        {/* <Title text="Writing" /> */}
        <div>
        {posts.map((post, i) => (
          <div className="border-t-4 border-solid border-opacity-80 border-red-500 bg-white rounded-md shadow-xl my-5 mx-auto w-full px-4 py-2">
            <Link key={i} to={`writing/${post.node.uid}`}>
              <h1 className="font-mon text-lg mb-1 mt-1">{post.node.data.title.text}</h1>
            </Link>
              {post.node.data.subtitle.text 
                ? <h6 className="font-display text-md font-normal mt-1 mb-2">{post.node.data.subtitle.text}</h6>
                : null
              }
              {post.node.data.tag
                ? <h6 className="w-14 h-6 text-center bg-red-500 rounded-sm font-display text-md font-bold text-white my-1 pt-1 pb-2">{post.node.data.tag}</h6>
                : null
              }
              {/* add img below for lg screens */}
              {/* {post.node.data.cover_image.???
                ? <div className="hidden lg:block"></div>
                : null
              } */}
          </div>
          ))}
        </div>
        {/* <p className="mt-24 ml-80">Add pages 1,2,3 etc. with arrow icons both ways</p> */}
        </React.Fragment>
  )
}


