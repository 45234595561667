import React from "react"
//import { Link } from "gatsby"
import '../styles/global.css'
import "@fontsource/josefin-sans"
import "@fontsource/poppins"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
//import Final from "../images/final.inline.svg"
import Background from "../images/bgright.inline.svg"
import Bigback from "../images/right-back.inline.svg"
// import Medback from "../images/bgrightmd.inline.svg"
import Bottomback from "../images/bgleft.inline.svg"
//import indexStyles from './index.module.css'
import Nav from '../components/nav'
import Podlinks from '../components/podlinks'
//import { render } from "react-dom"
import Content from "../components/content"


export default class IndexPage extends React.Component {
  constructor(props) {
    super(props) 

    this.state = {
      navSelect: 'Episodes',
      error: null,
    }

    this.updateSelect = this.updateSelect.bind(this)
  }

  componentDidMount() {
    this.updateSelect(this.state.navSelect)
  }

  updateSelect(navSelect) {
    this.setState({
      navSelect,
      error: null,
    })
  }

  render() {
    const { navSelect } = this.state

    return (
    <>
    <Background className="absolute md:hidden top-0 right-0 -z-10"/>
    <Bigback className="absolute block top-0 right-0 -z-10"/>
    <Layout>
    <h1 className="banner-title font-mon mb-9 md:hidden">The Narrative Monopoly Podcast </h1>
    <div className="md:flex flex-row flex-nowrap min-h-screen w-full sm:py-6 md:px-20 justify-center">
      <SEO title="Home" />
        <div className="flex-1">
              <Image />
            <Podlinks />
          {/* <Link to="/page-2/" className="font-mon">Go to page 2</Link> <br />
          <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
        </div>
        <div className="flex-grow px-6 md:ml-6">
          <h1 className="banner-title font-mon hidden md:block text-center">The Narrative Monopoly Podcast </h1>
          <Nav onChange={this.updateSelect} navSelect={navSelect}/>
          <div className="my-3 mx-2"> 
            <Content navSelect={navSelect}/>
          </div>
        </div>
      </div>    
    </Layout>
  <Bottomback className="sticky bottom-0 left-0 -z-10"/>
  <footer className="bg-opacity-0 h-8 w-full border-t-2 object-bottom">The Narrative Monopoly Podcast © {new Date().getFullYear()}</footer>
  </>
    )
  }
}


