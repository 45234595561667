import React from 'react'
import PropTypes from 'prop-types'
import navStyles from './mods/nav.module.css'
import { Link } from 'gatsby'

export default function Nav({ navSelect, onChange }) {
    const options = ['Episodes','Writing','About']

    let epSelect = navSelect === 'Episodes';
    let writeSelect = navSelect === 'Writing';
    let aboutSelect = navSelect === 'About';

    return (
      <React.Fragment>
        <div className={navStyles.headerInside}>
            {options.map((opt, i) => (
                <h1 key={i}>
                    <Link 
                        to="/" 
                        onClick={() => onChange(opt)}
                        className={opt === navSelect ? `${navStyles.active}` : null }
                    >
                        {opt}
                    </Link>
                </h1>
            ))}
        </div>
    </React.Fragment>      
    )
}

{/* <h1>
<Link to="/" className={`${navSelect} == 'Episodes' ? 'navStyles.active' : null`} onClick={() => onChange()}>
    Episodes
</Link>
</h1>
<h1>
<Link to="/" onClick={() => onChange()}>
    Writing
</Link>
</h1>
<h1>
<Link to="/" onClick={() => onChange()}>
    About
</Link>
</h1> */}



// Nav.propTypes = {
// siteTitle: PropTypes.string,
// }

// Nav.defaultProps = {
// siteTitle: ``,
// }